/**
 * @param {string} key - The string
 */
export const _getEnv = (key) => {
  return process.env[key] || null;
};
export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
