import { ImageComponent } from "../common";

const HeroComponent = ({
  h1Text,
  h1SpanText,
  pText,
  btn1Text,
  onClickBtn1,
  btn2Text,
  onClickBtn2,
  imageName,
}) => {
  return (
    <div className="hero">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="intro-excerpt">
              <h1>
                {h1Text} <span clsas="d-block">{h1SpanText}</span>
              </h1>
              <p className="mb-4">{pText}</p>
              <p>
                {!!btn1Text && <button
                  className="btn btn-secondary me-2"
                  onClick={onClickBtn1}
                >
                  {btn1Text}
                </button>}
                {!!btn2Text && <button className="btn btn-white-outline" onClick={onClickBtn2}>
                  {btn2Text}
                </button>}
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="hero-img-wrap">
              <ImageComponent imageName={imageName} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroComponent;
