import { Link } from "react-router-dom";
import { APP_ROUTES_, IMAGES_ } from "../../shared/constants";
import { _getEnv } from "../../shared/functions";
import { ImageComponent } from "./";

const FooterComponent = () => {
  return (
    <footer className="footer-section">
      <div className="container relative">
        {/*<div className="sofa-img">
          <ImageComponent
            imageName={IMAGES_.SOFA}
            alt="Image"
            className="img-fluid"
          />
        </div>*/}

        {/*<div className="row">
          <div className="col-lg-8">
            <div className="subscription-form">
              <h3 className="d-flex align-items-center">
                <span className="me-1">
                  <ImageComponent
                    imageName="envelope-outline.svg"
                    alt="Image"
                    className="img-fluid"
                  />
                </span>
                <span>Subscribe to Newsletter</span>
              </h3>

              <form action="#" className="row g-3">
                <div className="col-auto">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="col-auto">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="col-auto">
                  <button className="btn btn-primary">
                    <span className="fa fa-paper-plane"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>*/}

        <div className="row g-5 mb-5">
          <div className="col-lg-4">
            <div className="mb-4 footer-logo-wrap">
              <a href="#" className="footer-logo">
                {_getEnv("REACT_APP_TITLE")}
                <span>.</span>
              </a>
            </div>
            <p className="mb-4">
              We are an online printing company. This might seem oversimplified. But it's better than that usual elongated description
            </p>

            {/*<ul className="list-unstyled custom-social">
              <li>
                <a href="#">
                  <span className="fa fa-brands fa-facebook-f"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fa fa-brands fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fa fa-brands fa-instagram"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fa fa-brands fa-linkedin"></span>
                </a>
              </li>
            </ul>*/}
          </div>

          {/*<div className="col-lg-8">
            <div className="row links-wrap">
              <div className="col-6 col-sm-6 col-md-3">
                <ul className="list-unstyled">
                  <li>
                    <Link to={APP_ROUTES_.ABOUT}>About us</Link>
                  </li>
                  <li>
                    <Link to={APP_ROUTES_.SERVICES}>Services</Link>
                  </li>
                  <li>
                    <Link to={APP_ROUTES_.BLOG}>Blog</Link>
                  </li>
                  <li>
                    <Link to={APP_ROUTES_.CONTACT}>Contact us</Link>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-sm-6 col-md-3">
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Support</a>
                  </li>
                  <li>
                    <a href="#">Knowledge base</a>
                  </li>
                  <li>
                    <a href="#">Live chat</a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-sm-6 col-md-3">
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Jobs</a>
                  </li>
                  <li>
                    <Link to={APP_ROUTES_.ABOUT_OUR_TEAM}>Our team</Link>
                  </li>
                  <li>
                    <a href="#">Leadership</a>
                  </li>
                  <li>
                    <Link to={APP_ROUTES_.PRIVACY}>Privacy Policy</Link>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-sm-6 col-md-3">
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Nordic Chair</a>
                  </li>
                  <li>
                    <a href="#">Kruzo Aero</a>
                  </li>
                  <li>
                    <a href="#">Ergonomic Chair</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>*/}
        </div>

        <div className="border-top copyright">
          <div className="row pt-4">
            <div className="col-lg-6">
              <p className="mb-2 text-center text-lg-start">
                Copyright &copy;{new Date().getFullYear()}. All Rights Reserved.
              </p>
            </div>

            <div className="col-lg-6 text-center text-lg-end">
              <ul className="list-unstyled d-inline-flex ms-auto">
                {/*<li className="me-4">
                  <Link to={APP_ROUTES_.TERMS_CONDITIONS}>
                    Terms &amp; Conditions
                  </Link>
                </li>*/}
                <li>
                  <Link to={APP_ROUTES_.PRIVACY}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterComponent;
