import { ImageComponent } from "../../components/common";
import HeroComponent from "../../components/hero";
import { _getEnv } from "../../shared/functions";

const PrivacyPage = () => {
  const privacyPolicy = [
    {
      title: ``,
      lines: [`<b>&ldquo;We Take Your Privacy Seriously&rdquo;</b>`],
    },
    {
      title: "",
      lines: [
        `<b>${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )}</b> values its customers and respects their privacy. We collect customer information in an effort to improve your shopping experience and to communicate with you about our products, services, contests, and promotions.`,
        `${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} recognizes that it must maintain and use customer information responsibly. We do not sell or rent the information you provide to us online to third parties. However, we do share your information with third parties under certain circumstances as detailed below.`,
        `This policy describes the personal information we collect about you, why we collect it, how we use it and when we share it with third parties. Our policy also describes the choices you can make about how we collect and use your information. If you have any questions about our privacy policy, please contact ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} customer service via email at ${_getEnv("REACT_APP_SUPPORT_EMAIL")}`,
        ``,
        `If our information practices change in the future, we will let you know by posting the policy changes on our Web site or by communicating directly with you. We will notify you of any such change before we use your information in a manner not previously disclosed in our privacy policy. You will have the opportunity at that time to opt out of any new use of your information.`,
      ],
    },
    {
      title: "WHAT WE COLLECT",
      lines: [
        `We collect information (such as your name, email address, mailing address, phone number) that you provide when you place an order, save your information with us or participate in a contest, promotion or survey. We also maintain a record of your product interests and your purchases online and in our stores. All the information we collect is given to third parties ONLY if they are involved in completing your order.`,
      ],
    },
    {
      title: "HOW WE USE YOUR INFORMATION",
      lines: [
        `When you make an online purchase from ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )}, we use your information to process your order. We will also send you emails to confirm your order, and we may need to contact you via phone, postal mail or email if we have other questions regarding your order. In addition, you will be automatically signed up to receive email updates about our products, services, contests, and promotions.`,
        `When you save your information with ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )}, you will also be automatically signed up to receive email updates. If you participate in a contest or promotion (online or over the phone), you may choose to receive email updates about our products, services, contests, and promotions.`,
        `If at any time you would like us to remove you from our email or postal mailing lists, feel free to contact ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} customer service at ${_getEnv(
          "REACT_APP_SUPPORT_EMAIL"
        )}. Please be sure to include full name and any other relevant information. If you have previously asked to be removed from our email list, we will not sign you up for email updates if you place an order over the Internet. Please note that it may take up to 8 weeks to remove your from our list.`,
        `From time to time, we may also send you emails to help us learn more about your shopping preferences. In addition, we may use information about your product interests and purchases to help us improve our site design and your shopping experience. If you make a purchase online or over the phone, we may also send you notices about our products, services, contests and promotions through postal mail.`,
        `To serve you better, we may combine information you give us online and in our stores as card card-body as information about your product interests and purchases. In addition, we may combine information you provide with demographic information that is publicly available. We use this combined information to enhance and personalize your shopping experience with us, and to communicate with you by email or postal mail about our products, services, contests, and promotions that may be of interest to you. If you do not want us to use your information as described above, please contact ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} service by emailing ${_getEnv("REACT_APP_SUPPORT_EMAIL")}`,
      ],
    },
    {
      title: "THIRD PARTY TRANSACTIONS",
      lines: [
        `We contract with third parties to assist us in maintaining and managing our customer information to fulfill promotions and to communicate with our customers. We do not authorize any of those third parties to make any other use of your information.`,
        `In addition, when we team up with another company to offer or provide products, services, contests or promotions, we and the other company may cross-reference our customer databases to identify common customers. We and the other company may use that existing information to identify future promotional opportunities and to fulfill promotional or contractual obligations. When we offer contests and promotions, or join with other companies to do so, customers who choose to participate in those contests and promotions may be asked to provide personal information to participate. That information may then be used by us and any company offering the contest or promotion to notify winners or to fulfill promotional obligations.`,
        `If prior to participating in a contest or promotion, you opted not to receive information from us, you may still receive information from or about ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} in connection with the contest or promotion if you choose to participate in it. You may opt not to participate in these contests or promotions if you do not want to receive information about ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} or share your information with us and the other companies sponsoring those contests or promotions.`,
        `We also may share aggregate, non-personally identifiable information about you (such as age, gender, and types of products purchased) with our business partners to fulfill our contractual obligations. Except as described above, we do not share, rent or sell any information we collect online to any unaffiliated third party.`,
      ],
    },
    {
      title: "ACCESSING YOUR INFORMATION",
      lines: [
        `To access your information online, simply click the ACCOUNT link to sign in, and then choose the appropriate section to review, edit, or delete your personal information. Please note that any requests to change the frequency that we send email updates to you may take up eight weeks.`,
        `If at any time you would like us to remove you from our email or postal mailing lists or if you do not want us to use your information to enhance your shopping experience, feel free to contact ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} customer service via email at ${_getEnv(
          "REACT_APP_SUPPORT_EMAIL"
        )}. Please be sure to include your email address and full name.`,
      ],
    },
    {
      title: "REMOVING YOURSELF FROM MAILOUTS",
      lines: [
        `We want to communicate with you only if you want to hear from us. If you prefer not to receive information from us, either through email, postal mail or by phone, please let us know by sending us an email at ${_getEnv(
          "REACT_APP_SUPPORT_EMAIL"
        )}`,
        `Please note that such requests may take up to six to eight weeks to become effective. In addition, if you ask us to remove your name and address from our email or postal mailing lists, we will maintain your name in a "do not mail" file to ensure that we can honor your request. Please also note that when you make a purchase, we will send you an email to confirm your order and may also need to contact you via phone, postal mail or email if we have other questions regarding your order.`,
      ],
    },
    {
      title: "CHILDREN'S PRIVACY",
      lines: [
        `We at ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )} are concerned about the safety of children when they use the Internet. Consistent with the Children's Online Privacy Protection Act of 1998, we will never knowingly request personally identifiable information from anyone under the age of 13 without prior verifiable parental consent.`,
        `If we become aware that a subscriber is under the age of 13 and has signed up without prior verifiable parental consent, we will remove his or her personally identifiable information from our files.`,
      ],
    },
    {
      title: "COOKIES",
      lines: [
        `A cookie is a small data file that Web sites often store on your computer's hard drive when you visit their sites. A cookie may contain information (such as a unique user ID), that is used to track the pages of the sites you've visited. We use cookies but do not store personally identifiable information in your cookie.`,
        `We use cookies in order to improve your shopping experience. When you visit ${_getEnv(
          "REACT_APP_COMPANY_NAME"
        )}, your cookie helps us keep track of your order as you shop at our site. If you have saved your information with us, your cookie allows us to recognize you when you return to our site and provides you with access to your account information. If you saved your information with or order from us, we also use cookies to monitor and maintain information about your use of our site. If you have not saved your information with or ordered from us, we may monitor and maintain information about your use of our Web site in a manner that does not identify you. In either case, this information helps us serve you better by improving our site design, as card card-body as our products, services, contests, and promotions.`,
        `We also use cookies to track and maintain the identity of the Web site you visited immediately prior to ours to further improve our site design and to fulfill contracts with our business partners. We do not otherwise track any information about your use of other Web sites.`,
        `You can refuse cookies by turning them off in your browser. If you turn off cookies, though, we will not be able to track your order or enable you to make a purchase from our site. Nor will we be able to recognize you as a signed up user to allow you access to your account information.`,
      ],
    },
  ];
  return (
    <>
      <HeroComponent h1Text="Privacy Policy" />
      <div className="testimonial-section before-footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {privacyPolicy.map((val, idx) => {
                return (
                  <div key={`privacy_${idx}`}>
                    <div className="row">
                      <div className="col-lg-12 mx-auto">
                        <h2 className="section-title">{val.title}</h2>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testimonial-block">
                        <blockquote className="mb-5">
                          {val.lines.map((line, idx2) => {
                            return (
                              <p
                                key={`line_${idx2}`}
                                dangerouslySetInnerHTML={{ __html: line }}
                              />
                            );
                          })}
                        </blockquote>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPage;
