import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/common/FooterComponent";
import HeaderComponent from "./components/common/HeaderComponent";
import { APP_ROUTES_ } from "./shared/constants";
import HomePage from "./pages/home";
import NotFoundPage from "./pages/not-found";
import ShopPage from "./pages/shop";
import AboutPage from "./pages/about";
import ServicesPage from "./pages/services";
import BlogPage from "./pages/blog";
import ContactPage from "./pages/contact";
import CartPage from "./pages/cart";
import PrivacyPage from "./pages/privacy";
import ThankyouPage from "./pages/thankyou";
import TermsConditions from "./pages/terms-conditions";
import { ScrollToElement } from "./components/common";

const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToElement />
        <HeaderComponent />
        <Routes>
          <Route path={APP_ROUTES_.HOME} element={<HomePage />} />
          <Route path={APP_ROUTES_.SHOP} element={<ShopPage />} />
          <Route path={APP_ROUTES_.ABOUT} element={<AboutPage />} />
          <Route path={APP_ROUTES_.SERVICES} element={<ServicesPage />} />
          <Route path={APP_ROUTES_.BLOG} element={<BlogPage />} />
          <Route path={APP_ROUTES_.CONTACT} element={<ContactPage />} />
          <Route path={APP_ROUTES_.CART} element={<CartPage />} />
          <Route path={APP_ROUTES_.PRIVACY} element={<PrivacyPage />} />
          <Route path={APP_ROUTES_.THANKYOU} element={<ThankyouPage />} />
          <Route path={APP_ROUTES_.TERMS_CONDITIONS} element={<TermsConditions />} />
          <Route path={APP_ROUTES_.NOT_FOUND} element={<NotFoundPage />} />
        </Routes>
        <FooterComponent />
      </Router>
    </div>
  );
};

export default App;
