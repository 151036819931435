import { Link, useLocation } from "react-router-dom";
import { ImageComponent } from "./";
import { APP_ROUTES_ } from "../../shared/constants";
import { _getEnv } from "../../shared/functions";

const HeaderComponent = () => {
  const location = useLocation();
  const navBar = [
    { title: "Home", path: APP_ROUTES_.HOME, activeIn: [APP_ROUTES_.HOME] },
    { title: "Shop", path: APP_ROUTES_.SHOP, activeIn: [APP_ROUTES_.SHOP] },
    { title: "About us", path: APP_ROUTES_.ABOUT, activeIn: [APP_ROUTES_.ABOUT] },
    { title: "Services", path: APP_ROUTES_.SERVICES, activeIn: [APP_ROUTES_.SERVICES] },
    { title: "Blog", path: APP_ROUTES_.BLOG, activeIn: [APP_ROUTES_.BLOG] },
    { title: "Contact us", path: APP_ROUTES_.CONTACT, activeIn: [APP_ROUTES_.CONTACT] },
    { title: "", path: APP_ROUTES_.HOME, img: "user.svg", activeIn: [APP_ROUTES_.HOME] },
    { title: "", path: APP_ROUTES_.CART, img: "cart.svg", activeIn: [APP_ROUTES_.CART] }
  ]
  return (
    <>
      <nav
        className="custom-navbar navbar navbar navbar-expand-md navbar-dark bg-dark"
        arial-label="PrachiPrints navigation bar"
      >
        <div className="container">
          <Link to={APP_ROUTES_.HOME} className="navbar-brand">
            {_getEnv("REACT_APP_TITLE")}
            <span>.</span>
          </Link>
          {/*<button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarsFurni">
            <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
              {navBar.map((val, idx) => {
                return <li
                className={
                  val.activeIn.includes(location.pathname)
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <Link to={val.path} className="nav-link">
                  {val.title}
                  {val?.img && <ImageComponent imageName={val?.img} />}
                </Link>
              </li>
              })}
            </ul>
          </div>*/}
        </div>
      </nav>
    </>
  );
};
export default HeaderComponent;
