import { useEffect, useState } from "react";
import { isValidUrl } from "../../shared/functions";
import { useLocation } from "react-router-dom";

export const ImageComponent = ({ imageName, className }) => {
  const [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    const importImage = async () => {
      try {
        const module = await import(`../../assets/images/${imageName}`);
        setImagePath(module.default);
      } catch (error) {
        console.error(`Error importing image(${imageName}): ${error.message}`);
      }
    };

    if (imageName && !isValidUrl(imageName)) {
      importImage();
    } else if (imageName && isValidUrl(imageName)) {
      setImagePath(imageName);
    }
  }, [imageName]);

  return (
    <>
      {imagePath && (
        <img
          src={imagePath}
          className={className}
          alt={`Image: ${imageName}`}
        />
      )}
    </>
  );
};

export const ScrollToElement = () => {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (hash) {
      try {
        const elementRef = document.getElementById(hash.split("#")[1]);
        elementRef.scrollIntoView();
      } catch (e) {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
};
