import { Carousel } from "react-bootstrap";
import { ImageComponent } from "../../components/common";
import HeroComponent from "../../components/hero";
import { IMAGES_ } from "../../shared/constants";
import {_getEnv} from "../../shared/functions";
import {Link} from "react-router-dom";

const HomePage = () => {
	const onHandleShopNow = () => {

	}

  return (
    <>
      <HeroComponent
        h1Text="Hassle Free"
        h1SpanText="Printing"
        pText={`Customized online printing and gifting solutions for corporates & SMEs. Get personalized company branded products with business name & logo.`}
        btn1Text="Shop Now"
        onClickBtn1={onHandleShopNow}

      />

      {/* Start Product Section */}
      <div className="product-section">
        <div className="container">
          <div className="row">
            {/* Start Column 1 */}
            <div className="col-md-12 col-lg-3 mb-5 mb-lg-0">
              <h2 className="mb-4 section-title">
                Hundreds of beautiful ready to use templates
              </h2>
              <p className="mb-4">
                More than 100+ print products for your brand. They range from Visiting Cards, Letterheads, Envelopes, Banners, Brochures, Posters, Flyers and many more products.
              </p>
              <p>
                <button onClick={() => {}} className="btn">
                  Explore
                </button>
              </p>
            </div>
            {/* End Column 1 */}

            {/* Start Column 2 */}
            <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
              <Link to={`#`} className="product-item">
                <ImageComponent
                  imageName="visiting-cards-category94.jpg"
                  className="img-fluid product-thumbnail"
                />
                <h3 className="product-title">Envelopes, visiting cards, id cards & accessories,  business essentials, diaries & notebooks, personal stationery</h3>
                <strong className="product-price">Stationery</strong>

                <span className="icon-cross">
                  <ImageComponent imageName="cross.svg" className="img-fluid" />
                </span>
              </Link>
            </div>
            {/* End Column 2 */}

            {/* Start Column 3 */}
            <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
              <Link to={`#`} className="product-item">
                <ImageComponent
                  imageName="marketing-banners-_-posters-th_6296629966.jpg"
                  className="img-fluid product-thumbnail"
                />
                <h3 className="product-title">Flyers & documents, brochures & booklets, banners & posters, retail, calendars, invitation & greeting cards, other marketing products</h3>
                <strong className="product-price">Marketing</strong>

                <span className="icon-cross">
                  <ImageComponent imageName="cross.svg" className="img-fluid" />
                </span>
              </Link>
            </div>
            {/* End Column 3 */}

            {/* Start Column 4 */}
            <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
              <Link to={`#`} className="product-item">
                <ImageComponent
                  imageName="design-services-cataegory18.jpg"
                  className="img-fluid product-thumbnail"
                />
                <h3 className="product-title">Photo products, design services, eco-friendly kraft pads - 2 and 3 fold kraft pads, Notebook Kraft Pads, Magnet Kraft Pads, Cube Kraft Pads</h3>
                <strong className="product-price">Other Products</strong>

                <span className="icon-cross">
                  <ImageComponent imageName="cross.svg" className="img-fluid" />
                </span>
              </Link>
            </div>
            {/* End Column 4 */}
          </div>
        </div>
      </div>
      {/* End Product Section */}

      {/* Start Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <h2 className="section-title">Why Choose Us</h2>
              <p></p>

              <div className="row my-5">
                <div className="col-6 col-md-6">
                  <div className="feature">
                    <div className="icon">
                      <ImageComponent
                        imageName="truck.svg"
                        alt="Image"
                        className="imf-fluid"
                      />
                    </div>
                    <h3>Fast &amp; Free Shipping</h3>
                    <p>
                      Order from your office desk or your living room anytime and get your products delivered anywhere in India, it's that simple!
                    </p>
                  </div>
                </div>

                <div className="col-6 col-md-6">
                  <div className="feature">
                    <div className="icon">
                      <ImageComponent
                        imageName="bag.svg"
                        alt="Image"
                        className="imf-fluid"
                      />
                    </div>
                    <h3>Easy to Shop</h3>
                    <p>
                      We have more than 100+ print products for your brand. They range from Visiting Cards, Letterheads, Envelopes, Banners, Brochures, Posters, Flyers and many more products.
                    </p>
                  </div>
                </div>

                <div className="col-6 col-md-6">
                  <div className="feature">
                    <div className="icon">
                      <ImageComponent
                        imageName="support.svg"
                        alt="Image"
                        className="imf-fluid"
                      />
                    </div>
                    <h3>Your Reliable Partner</h3>
                    <p>
                      We believe in being there for you every day, ensuring that your needs are met promptly and efficiently, regardless of the day of the week.
                    </p>
                  </div>
                </div>

                <div className="col-6 col-md-6">
                  <div className="feature">
                    <div className="icon">
                      <ImageComponent
                        imageName="return.svg"
                        alt="Image"
                        className="imf-fluid"
                      />
                    </div>
                    <h3>Ready-to-use designs</h3>
                    <p>
                      {_getEnv("REACT_APP_TITLE")} offers 100s of ready-to-use designs which can be easily customised to your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="img-wrap">
                <ImageComponent
                  imageName="why-choose-us-img.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Why Choose Us Section */}

      {/* Start We Help Section */}
      {/*<div className="we-help-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <div className="imgs-grid">
                <div className="grid grid-1">
                  <ImageComponent imageName="img-grid-1.jpg" alt="Untree.co" />
                </div>
                <div className="grid grid-2">
                  <ImageComponent imageName="img-grid-2.jpg" alt="Untree.co" />
                </div>
                <div className="grid grid-3">
                  <ImageComponent imageName="img-grid-3.jpg" alt="Untree.co" />
                </div>
              </div>
            </div>
            <div className="col-lg-5 ps-lg-5">
              <h2 className="section-title mb-4">
                We Help You Make Modern Interior Design
              </h2>
              <p>
                Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio
                quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam
                vulputate velit imperdiet dolor tempor tristique. Pellentesque
                habitant morbi tristique senectus et netus et malesuada
              </p>

              <ul className="list-unstyled custom-list my-4">
                <li>Donec vitae odio quis nisl dapibus malesuada</li>
                <li>Donec vitae odio quis nisl dapibus malesuada</li>
                <li>Donec vitae odio quis nisl dapibus malesuada</li>
                <li>Donec vitae odio quis nisl dapibus malesuada</li>
              </ul>
              <p>
                <a herf="#" className="btn">
                  Explore
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>*/}
      {/* End We Help Section */}

      {/* Start Popular Product */}
      {/*<div className="popular-product">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="product-item-sm d-flex">
                <div className="thumbnail">
                  <ImageComponent
                    imageName="product-1.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
                <div className="pt-3">
                  <h3>Nordic Chair</h3>
                  <p>
                    Donec facilisis quam ut purus rutrum lobortis. Donec vitae
                    odio{" "}
                  </p>
                  <p>
                    <a href="#">Read More</a>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="product-item-sm d-flex">
                <div className="thumbnail">
                  <ImageComponent
                    imageName="product-2.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
                <div className="pt-3">
                  <h3>Kruzo Aero Chair</h3>
                  <p>
                    Donec facilisis quam ut purus rutrum lobortis. Donec vitae
                    odio{" "}
                  </p>
                  <p>
                    <a href="#">Read More</a>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="product-item-sm d-flex">
                <div className="thumbnail">
                  <ImageComponent
                    imageName="product-3.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
                <div className="pt-3">
                  <h3>Ergonomic Chair</h3>
                  <p>
                    Donec facilisis quam ut purus rutrum lobortis. Donec vitae
                    odio{" "}
                  </p>
                  <p>
                    <a href="#">Read More</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      {/* End Popular Product */}

      {/* Start Testimonial Slider */}
      {/*<div className="testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto text-center">
              <h2 className="section-title">Testimonials</h2>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="testimonial-slider-wrap text-center">
							<Carousel variant="dark" interval="3500" className="testimonial-slider">
                <Carousel.Item className="item">
								<div className="row justify-content-center mb-5">
                      <div className="col-lg-8 mx-auto">
                        <div className="testimonial-block text-center">
                          <blockquote className="mb-5">
                            <p>
                              &ldquo;Donec facilisis quam ut purus rutrum
                              lobortis. Donec vitae odio quis nisl dapibus
                              malesuada. Nullam ac aliquet velit. Aliquam
                              vulputate velit imperdiet dolor tempor tristique.
                              Pellentesque habitant morbi tristique senectus et
                              netus et malesuada fames ac turpis egestas.
                              Integer convallis volutpat dui quis
                              scelerisque.&rdquo;
                            </p>
                          </blockquote>

                          <div className="author-info">
                            <div className="author-pic">
                              <ImageComponent
                                imageName="person-1.png"
                                alt="Maria Jones"
                                className="img-fluid"
                              />
                            </div>
                            <h3 className="font-weight-bold">Maria Jones</h3>
                            <span className="position d-block mb-3">
                              CEO, Co-Founder, XYZ Inc.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
								</Carousel.Item>
                <Carousel.Item className="item">
								<div className="row justify-content-center mb-5">
                      <div className="col-lg-8 mx-auto">
                        <div className="testimonial-block text-center">
                          <blockquote className="mb-5">
                            <p>
                              &ldquo;Donec facilisis quam ut purus rutrum
                              lobortis. Donec vitae odio quis nisl dapibus
                              malesuada. Nullam ac aliquet velit. Aliquam
                              vulputate velit imperdiet dolor tempor tristique.
                              Pellentesque habitant morbi tristique senectus et
                              netus et malesuada fames ac turpis egestas.
                              Integer convallis volutpat dui quis
                              scelerisque.&rdquo;
                            </p>
                          </blockquote>

                          <div className="author-info">
                            <div className="author-pic">
                              <ImageComponent
                                imageName="person-1.png"
                                alt="Maria Jones"
                                className="img-fluid"
                              />
                            </div>
                            <h3 className="font-weight-bold">Maria Jones</h3>
                            <span className="position d-block mb-3">
                              CEO, Co-Founder, XYZ Inc.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
								</Carousel.Item>
                <Carousel.Item className="item">
								<div className="row justify-content-center mb-5">
                      <div className="col-lg-8 mx-auto">
                        <div className="testimonial-block text-center">
                          <blockquote className="mb-5">
                            <p>
                              &ldquo;Donec facilisis quam ut purus rutrum
                              lobortis. Donec vitae odio quis nisl dapibus
                              malesuada. Nullam ac aliquet velit. Aliquam
                              vulputate velit imperdiet dolor tempor tristique.
                              Pellentesque habitant morbi tristique senectus et
                              netus et malesuada fames ac turpis egestas.
                              Integer convallis volutpat dui quis
                              scelerisque.&rdquo;
                            </p>
                          </blockquote>

                          <div className="author-info">
                            <div className="author-pic">
                              <ImageComponent
                                imageName="person-1.png"
                                alt="Maria Jones"
                                className="img-fluid"
                              />
                            </div>
                            <h3 className="font-weight-bold">Maria Jones</h3>
                            <span className="position d-block mb-3">
                              CEO, Co-Founder, XYZ Inc.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
								</Carousel.Item>
							</Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      {/* End Testimonial Slider */}

      {/* Start Blog Section */}
      {/*<div className="blog-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-6">
              <h2 className="section-title">Recent Blog</h2>
            </div>
            <div className="col-md-6 text-start text-md-end">
              <a href="#" className="more">
                View All Posts
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
              <div className="post-entry">
                <a href="#" className="post-thumbnail">
                  <ImageComponent
                    imageName="post-1.jpg"
                    alt="Image"
                    className="img-fluid"
                  />
                </a>
                <div className="post-content-entry">
                  <h3>
                    <a href="#">First Time Home Owner Ideas</a>
                  </h3>
                  <div className="meta">
                    <span>
                      by <a href="#">Kristin Watson</a>
                    </span>{" "}
                    <span>
                      on <a href="#">Dec 19, 2021</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
              <div className="post-entry">
                <a href="#" className="post-thumbnail">
                  <ImageComponent
                    imageName="post-2.jpg"
                    alt="Image"
                    className="img-fluid"
                  />
                </a>
                <div className="post-content-entry">
                  <h3>
                    <a href="#">How To Keep Your Furniture Clean</a>
                  </h3>
                  <div className="meta">
                    <span>
                      by <a href="#">Robert Fox</a>
                    </span>{" "}
                    <span>
                      on <a href="#">Dec 15, 2021</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
              <div className="post-entry">
                <a href="#" className="post-thumbnail">
                  <ImageComponent
                    imageName="post-3.jpg"
                    alt="Image"
                    className="img-fluid"
                  />
                </a>
                <div className="post-content-entry">
                  <h3>
                    <a href="#">Small Space Furniture Apartment Ideas</a>
                  </h3>
                  <div className="meta">
                    <span>
                      by <a href="#">Kristin Watson</a>
                    </span>{" "}
                    <span>
                      on <a href="#">Dec 12, 2021</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      {/* End Blog Section */}
    </>
  );
}
export default HomePage;
