export const APP_ROUTES_ = {
  HOME: '/',
  NOT_FOUND: '*',
  SHOP: '/shop',
  ABOUT: '/about',
  SERVICES: '/services',
  BLOG: '/blog',
  CONTACT: '/contact',
  CART: '/cart',
  PRIVACY: '/privacy',
  THANKYOU: '/thankyou',
  TERMS_CONDITIONS: '/terms-conditions',
  ABOUT_OUR_TEAM: '/about#our-team',
}
export const IMAGES_ = {
  BANNER: 'couch.png',
  COUCH: 'logo.png',
  SOFA: 'sofa.png',
}
